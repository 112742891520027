import type React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import KybUboScreen from './KybUboScreen'
import StartKycScreen from './StartKycScreen'

type KycNavigatorProps = {}

const KycNavigator: React.FC<KycNavigatorProps> = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/start`}>
        <StartKycScreen />
      </Route>
      <Route path={`${path}/owners`}>
        <KybUboScreen />
      </Route>
    </Switch>
  )
}

export default KycNavigator

import { useDisclosure, useToast } from '@chakra-ui/react'
import { YearPickerInput } from '@mantine/dates'
import moment, { type Moment } from 'moment'
import type React from 'react'
import { useEffect, useState } from 'react'
import Calendar03Icon from '../../../../../assets/icons/calendar-03-stroke-rounded (1)'
import DefaultModal from '../../../../../components/DefaultModal'
import PageWrapper from '../../../../../components/Pagewrapper'
import Tooltip from '../../../../../components/Tooltip'
import {
  useGetFundPerformanceQuery,
  useUpdateFundPerformanceMutation,
} from '../../../../../redux/services/coreApi'
import LineChart from './components/LineChart'
import ReturnBadge from './components/ReturnBadge'

const generateMonths = (year: number) => {
  const months: Moment[] = []
  for (let i = 0; i < 12; i++) {
    //months.push(moment().month(i));
    months.push(moment([year, i, 1]))
  }
  return months
}

export type FormattedFundPerformance = {
  month: Moment
  performance?: string
  eurToUsdRate?: string
  performanceToDate?: string
}

type FundScreenProps = {}

const FundScreen: React.FC<FundScreenProps> = () => {
  const toast = useToast()
  const [monthlyReturns, setMontlyReturns] = useState<
    FormattedFundPerformance[] | null
  >(null)
  const modal = useDisclosure()
  const [monthData, setMonthData] = useState<{
    month: Moment
    performance?: string
    eurToUsdRate?: string
  } | null>(null)

  const [year, setYear] = useState<Date | null>(new Date())

  const { data: fundData, refetch: refetchFundData } =
    useGetFundPerformanceQuery({
      start: moment(year).startOf('year').toISOString(),
      end: moment(year).endOf('year').toISOString(),
    })
  const [updateFundPerformance] = useUpdateFundPerformanceMutation()

  useEffect(() => {
    refetchFundData()
  }, [year])

  useEffect(() => {
    if (!fundData || !year) return
    const months = generateMonths(year.getFullYear())

    const mergedData = months.map((month, monthIndex) => {
      const monthValue = fundData.find(data => data.month === monthIndex + 1)

      return {
        month,
        performance: monthValue
          ? ((monthValue.performance * 100)?.toString() ?? '')
          : undefined,
        performanceToDate: monthValue
          ? ((monthValue.performanceToDate * 100).toFixed(2)?.toString() ?? '')
          : undefined,
        eurToUsdRate: monthValue
          ? (monthValue.eurToUsdRate?.toString() ?? '')
          : undefined,
      }
    })
    setMontlyReturns(mergedData)
  }, [fundData])

  const hasMonthPassed = (m: Moment) => {
    const currentDate = moment()

    if (m.year() < currentDate.year()) {
      return true
    } else if (
      m.year() === currentDate.year() &&
      m.month() < currentDate.month()
    ) {
      return true
    }

    return false
  }

  const updatePerformance = async () => {
    if (
      !monthData ||
      !monthData.performance ||
      !monthData.eurToUsdRate ||
      !year
    )
      return
    const month = Number.parseInt(monthData?.month.format('M'))
    const performance = Number.parseFloat(monthData.performance)
    const eurToUsdRate = Number.parseFloat(monthData.eurToUsdRate)

    await updateFundPerformance({
      month,
      year: year.getFullYear(),
      performance,
      eurToUsdRate,
    }).unwrap()

    modal.onClose()

    toast({
      title: 'Fund performance updated',
      description: `${monthData.month.format('MMMM')} was successfully updated`,
      status: 'success',
    })
  }

  return (
    <PageWrapper title="Fund performance">
      <div className="grid grid-cols-12 gap-2">
        <YearPickerInput
          leftSection={
            <Calendar03Icon className="w-4 h-4 -mr-1 text-gray-500" />
          }
          leftSectionPointerEvents="none"
          placeholder="Select year"
          value={year}
          onChange={setYear}
          className="w-[100px] mb-2 font-regular"
          radius={'md'}
          size="sm"
        />
        <div className="col-span-full lg:col-span-8 lg:col-start-1">
          {monthlyReturns && <LineChart data={monthlyReturns} />}
        </div>
        <div className="col-span-full lg:col-span-4 lg:col-start-9 bg-white dark:bg-brand-dark-card border border-solid border-black/10 shadow-sm rounded-xl  relative">
          <div className="pt-4 pb-2 pl-6 font-semi">Monthly returns</div>
          <div className="p-2 overflow-y-auto lg:max-h-[450px] gap-1 flex flex-col">
            {monthlyReturns?.map((m, i) => {
              return (
                <Tooltip
                  label={
                    !hasMonthPassed(m.month)
                      ? "This month hasn't passed yet."
                      : ''
                  }
                >
                  <button
                    key={i}
                    disabled={!hasMonthPassed(m.month)}
                    onClick={() => {
                      setMonthData({
                        month: m.month,
                        performance: Number.parseFloat(
                          m.performance ?? '0'
                        )?.toString(),
                        eurToUsdRate: m.eurToUsdRate,
                      })
                      modal.onOpen()
                    }}
                    className={`py-2.5 px-4 w-full flex justify-between disabled:cursor-not-allowed disabled:opacity-50 select-none hover:bg-gray-50 dark:hover:bg-brand-dark-background rounded-lg dark:text-white cursor-pointer active:bg-gray-100 transition-all font-medium text-sm`}
                  >
                    {moment(m.month).format('MMMM YYYY')}
                    {m.performance && (
                      <ReturnBadge
                        value={Number.parseFloat(
                          Number.parseFloat(m.performance).toFixed(2)
                        )}
                        isDisabled={!hasMonthPassed(m.month)}
                      />
                    )}
                  </button>
                </Tooltip>
              )
            })}
          </div>
        </div>
      </div>
      <DefaultModal
        isOpen={modal.isOpen}
        onClose={modal.onClose}
        isLoading={false}
        whiteBG
      >
        <div className="p-5">
          <p className="font-semi">{monthData?.month.format('MMMM YYYY')}</p>

          <p className="text-description mt-5 mb-2">
            Enter value for {monthData?.month.format('MMMM YYYY')} in percent
          </p>
          <input
            type="text"
            className="input"
            value={monthData?.performance}
            onChange={e => {
              if (!monthData) return
              setMonthData({
                month: monthData?.month,
                performance: e.target.value,
                eurToUsdRate: monthData?.eurToUsdRate,
              })
            }}
            onKeyDown={async e => {
              if (e.code !== 'Enter') return
              await updatePerformance()
            }}
          />

          <p className="text-description mt-5 mb-2">
            Start of month EUR to USD Rate
          </p>
          <input
            type="text"
            className="input"
            value={monthData?.eurToUsdRate}
            onChange={e => {
              if (!monthData) return
              setMonthData({
                month: monthData?.month,
                performance: monthData?.performance,
                eurToUsdRate: e.target.value,
              })
            }}
            onKeyDown={async e => {
              if (e.code !== 'Enter') return
              await updatePerformance()
            }}
          />

          <div className="flex justify-end">
            <button
              className="button-secondary mt-5 max-w-[80px]"
              onClick={modal.onClose}
            >
              Cancel
            </button>
            <button
              className="button mt-5 max-w-[80px]"
              onClick={async () => {
                await updatePerformance()
              }}
            >
              Save
            </button>
          </div>
        </div>
      </DefaultModal>
    </PageWrapper>
  )
}

export default FundScreen

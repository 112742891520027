import { Spinner, useDisclosure } from '@chakra-ui/react'
import {
  faArrowRightArrowLeft,
  faCaretDown,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DropdownCell from '../../../../../components/DropdownCell'
import DropdownMenu from '../../../../../components/DropdownMenu'
import Icon from '../../../../../components/Icon'
import {
  ARYZE_FEE_STRUCTURE_ID,
  DEFAULT_PAY_CURRENCY_ISO_CODE,
  DEFAULT_RECEIVE_CURRENCY_ISO_CODE,
} from '../../../../../constants'
import useDebounce from '../../../../../hooks/useDebounce'
import {
  useGetBankAccountsQuery,
  useGetCurrenciesQuery,
  useGetFeeStructureQuery,
  useGetNetworksQuery,
  useGetQuotationQuery,
  useGetWalletsQuery,
} from '../../../../../redux/services/coreApi'
import type {
  BankAccountDto,
  CurrencyDto,
  NetworkDto,
  SwapDto,
  WalletDto,
} from '../../../../../types/coreApi-types'
import { handledErrorQuery } from '../../../../../utils/handledError'
import CurrencyModal from '../components/CurrencyModal/CurrencyModal'
import FeeContainer from '../components/FeeContainer'
import FormSection from '../components/FormSection'
import StatusModal from './StatusModal'
import SwapSummaryModal from './SwapSummaryModal'

type CreateSwapProps = {}

const Divider = () => {
  return (
    <div className="h-[1px] bg-brand-light-card-border dark:bg-brand-dark-card-border my-5 -mx-6"></div>
  )
}

const CreateSwap: React.FC<CreateSwapProps> = () => {
  const swapSummary = useDisclosure()
  const statusModal = useDisclosure()
  const currencyModal = useDisclosure()
  const [createdSwap, setCreatedSwap] = useState<SwapDto | null>(null)
  const { data: currenciesData } = useGetCurrenciesQuery()
  const [payCurrency, setPayCurrency] = useState<CurrencyDto | undefined>(
    undefined
  )
  const [receiveCurrency, setReceiveCurrency] = useState<
    CurrencyDto | undefined
  >(undefined)
  const [payAmount, setPayAmount] = useState<number>(Number.NaN)
  const [receiveAmount, setReceiveAmount] = useState<number>(Number.NaN)
  const [lastEditedAmountInput, setLastEditedAmountInput] = useState<
    'in' | 'out'
  >('in')
  const [wallet, setWallet] = useState<WalletDto | null>(null)
  const { data: walletsData } = useGetWalletsQuery(null)
  const [bankAccount, setBankAccount] = useState<BankAccountDto | null>(null)
  const { data: bankAccountsData } = useGetBankAccountsQuery(null)
  const { data: networksData } = useGetNetworksQuery(null)
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkDto | null>(
    null
  )
  const history = useHistory()

  const debounceMs = 400
  const _payAmount = useDebounce(payAmount, debounceMs)
  const _receiveAmount = useDebounce(receiveAmount, debounceMs)

  const {
    data: quotationData,
    isFetching: quotationIsFetching,
    error: quotationError,
  } = handledErrorQuery(() =>
    useGetQuotationQuery(
      payCurrency && receiveCurrency
        ? {
            amountIn:
              lastEditedAmountInput === 'in'
                ? isNaN(_payAmount)
                  ? 0
                  : _payAmount
                : 0,
            amountOut:
              lastEditedAmountInput === 'out'
                ? isNaN(_receiveAmount)
                  ? 0
                  : _receiveAmount
                : 0,
            currencyInId: payCurrency.id,
            currencyOutId: receiveCurrency.id,
          }
        : skipToken,
      { refetchOnMountOrArgChange: true }
    )
  )

  const isFetchingPayAmount =
    ((receiveAmount !== _receiveAmount && !isNaN(receiveAmount)) ||
      quotationIsFetching) &&
    lastEditedAmountInput === 'out'
  const isFetchingReceiveAmount =
    ((payAmount !== _payAmount && !isNaN(payAmount)) || quotationIsFetching) &&
    lastEditedAmountInput === 'in'

  const isAboutToFetchQuotation = isFetchingPayAmount || isFetchingReceiveAmount

  const hasSetDefaultCurrencyRef = useRef<boolean>(false)

  const payCurrencies = currenciesData
  const receiveCurrencies = currenciesData

  const { data: feeStructure } = useGetFeeStructureQuery()

  const hasAryzeFeeStructure =
    feeStructure?.id.toLowerCase() === ARYZE_FEE_STRUCTURE_ID.toLowerCase()

  let invalidCurrencyPair = undefined
  if (
    payCurrency?.currencyType === 'CryptoCurrency' &&
    receiveCurrency?.currencyType === 'CryptoCurrency'
  ) {
    if (!hasAryzeFeeStructure) {
      invalidCurrencyPair =
        'Crypto to Crypto pairs are currently not supported.'
    }
  } else if (
    payCurrency?.currencyType === 'FiatCurrency' &&
    receiveCurrency?.currencyType === 'FiatCurrency'
  ) {
    invalidCurrencyPair = 'Fiat to Fiat pairs are currently not supported.'
  } else if (receiveCurrency?.isoCode === 'USDT') {
    invalidCurrencyPair =
      'We do not support buying USDT at this time due to MiCA regulations.'
  }

  const isWalletAndCryptoCurrencyPairCompatible = useMemo(
    () => (wallet: WalletDto, currency: CurrencyDto) => {
      return Boolean(
        wallet &&
          wallet.network &&
          wallet.network?.cryptoCurrencies.find(x => x.id === currency.id)
      )
    },
    [wallet]
  )

  const isCryptoCurrencyAndNetworkPairCompatible = useMemo(
    () => (currency: CurrencyDto, network: NetworkDto) => {
      return network.cryptoCurrencies.find(x => x.id === currency.id)
    },
    [networksData, payCurrency]
  )

  // Set default currencies
  useEffect(() => {
    if (
      !hasSetDefaultCurrencyRef.current &&
      payCurrencies &&
      payCurrencies.length > 0 &&
      receiveCurrencies &&
      receiveCurrencies.length > 0
    ) {
      const defaultPayCurrency = payCurrencies.find(
        x => x.isoCode === DEFAULT_PAY_CURRENCY_ISO_CODE
      )
      if (defaultPayCurrency) {
        setPayCurrency(defaultPayCurrency)
      }

      const defaultReceiveCurrency = receiveCurrencies.find(
        x => x.isoCode === DEFAULT_RECEIVE_CURRENCY_ISO_CODE
      )
      if (defaultReceiveCurrency) {
        setReceiveCurrency(defaultReceiveCurrency)
      }

      hasSetDefaultCurrencyRef.current = true
    }
  }, [payCurrencies, receiveCurrencies])

  useEffect(() => {
    if (quotationData) {
      if (lastEditedAmountInput === 'in') {
        // TODO: Format number?
        // setReceiveAmount(formatNumber(quotationData.amountOut, receiveCurrency))
        setReceiveAmount(quotationData.amount)
      } else {
        setPayAmount(quotationData.amount)
      }
    }
  }, [quotationData, lastEditedAmountInput])

  const [currencyMode, setCurrencyMode] = useState<'pay' | 'receive'>('pay')

  return (
    <div className="card">
      {payCurrency && receiveCurrency && (
        <SwapSummaryModal
          isOpen={swapSummary.isOpen}
          onClose={swapSummary.onClose}
          payAmount={payAmount || 0}
          receiveAmount={receiveAmount}
          payCurrency={payCurrency}
          receiveCurrency={receiveCurrency}
          selectedWallet={wallet}
          selectedBankAccount={bankAccount}
          selectedNetwork={selectedNetwork}
          baseFeeRate={quotationData?.baseFee || 0}
          bankFeeRate={quotationData?.bankFee || 0}
          onSuccess={swap => {
            setPayAmount(Number.NaN)
            setReceiveAmount(Number.NaN)
            setWallet(null)
            setBankAccount(null)
            if (swap) {
              setCreatedSwap(swap)
              setTimeout(() => {
                statusModal.onOpen()
              }, 300)
            }
          }}
          lastEditedAmountInput={lastEditedAmountInput}
        />
      )}
      {createdSwap && (
        <StatusModal
          isOpen={statusModal.isOpen}
          onClose={() => {
            statusModal.onClose()
            setCreatedSwap(null)
          }}
          swap={createdSwap}
        />
      )}

      <CurrencyModal
        isOpen={currencyModal.isOpen}
        onClose={currencyModal.onClose}
        onClickCurrency={currency => {
          if (currencyMode === 'pay') {
            setPayCurrency(currency)

            const networksSupprtingCryptoCurrency = networksData?.filter(x =>
              x.cryptoCurrencies.find(x => x.id === currency.id)
            )
            if (networksSupprtingCryptoCurrency?.length === 1) {
              setSelectedNetwork(networksSupprtingCryptoCurrency[0])
            } else {
              if (
                selectedNetwork &&
                !isCryptoCurrencyAndNetworkPairCompatible(
                  currency,
                  selectedNetwork
                )
              ) {
                setSelectedNetwork(null)
              }
            }
          }
          if (currencyMode === 'receive') {
            setReceiveCurrency(currency)
            if (
              wallet &&
              !isWalletAndCryptoCurrencyPairCompatible(wallet, currency)
            ) {
              setWallet(null)
            }
          }
        }}
      />

      <FormSection title="Pay">
        <input
          className="input"
          type="number"
          name="you-pay"
          id="you-pay"
          placeholder="0.00"
          style={{
            ...{ borderColor: quotationError && '#D92424' },
          }}
          disabled={isFetchingPayAmount}
          value={Number.isNaN(payAmount) ? '' : payAmount}
          onChange={e => {
            setPayAmount(Number.parseFloat(e.target.value))
            if (lastEditedAmountInput !== 'in') setLastEditedAmountInput('in')
          }}
        />
        <div
          className="select-container w-[110px]"
          onClick={() => {
            setCurrencyMode('pay')
            currencyModal.onOpen()
          }}
        >
          <Icon src={payCurrency} size="base" />
          <span>{payCurrency?.isoCode}</span>
        </div>
      </FormSection>
      {quotationError && (
        <div className="text-[#D92424] text-sm">
          {'data' in quotationError &&
            (typeof (quotationError.data as any)?.errors === 'string'
              ? (quotationError.data as any)?.errors
              : "Unknown error, try again or contact Penning if it persists. The error is logged so we'll get on it.")}
        </div>
      )}

      <div className="mt-7 mb-1 flex justify-center">
        <FontAwesomeIcon
          icon={faArrowRightArrowLeft}
          className="text-sm text-brand-dark-text-description dark:text-brand-dark-text-subtle-description rotate-90"
        />
      </div>

      <FormSection title="Receive">
        <input
          className="input"
          type="number"
          name="you-receive"
          id="you-receive"
          placeholder="0.00"
          disabled={isFetchingReceiveAmount}
          value={Number.isNaN(receiveAmount) ? '' : receiveAmount}
          onChange={e => {
            setReceiveAmount(Number.parseFloat(e.target.value))
            if (lastEditedAmountInput !== 'out') setLastEditedAmountInput('out')
          }}
        />
        <div
          className="select-container w-[110px]"
          onClick={() => {
            setCurrencyMode('receive')
            currencyModal.onOpen()
          }}
        >
          <Icon src={receiveCurrency} size="base" />
          <span>{receiveCurrency?.isoCode}</span>
        </div>
      </FormSection>

      <FeeContainer
        payCurrency={payCurrency}
        exchangeRate={quotationData?.rate}
        receiveCurrency={receiveCurrency}
        baseFeeRate={quotationData?.baseFee || 0}
        bankFeeRate={quotationData?.bankFee || 0}
        payAmount={payAmount || 0}
        quotationIsFetching={isAboutToFetchQuotation}
      />

      <Divider />

      <div className="flex flex-col">
        {payCurrency?.currencyType === 'CryptoCurrency' && (
          <div className="mb-4">
            <div className="flex flex-col ">
              <p className="text-description-subtle mb-2">
                Network of payment currency
              </p>
              <DropdownMenu
                renderList={[...(networksData ?? [])]
                  .filter(x => x.cryptoCurrencies.length > 0)
                  .sort((a, b) => {
                    const compatibleA =
                      isCryptoCurrencyAndNetworkPairCompatible(payCurrency, a)
                    const compatibleB =
                      isCryptoCurrencyAndNetworkPairCompatible(payCurrency, b)
                    if (compatibleA && !compatibleB) return -1
                    return 0
                  })
                  .map(network => {
                    return (
                      <DropdownCell
                        key={network.id}
                        title={network.name}
                        subtitle={network.nativeCurrency?.isoCode}
                        onClick={() => setSelectedNetwork(network)}
                        isActive={network === selectedNetwork}
                        imageName={network}
                        disabled={
                          !isCryptoCurrencyAndNetworkPairCompatible(
                            payCurrency,
                            network
                          )
                        }
                      />
                    )
                  })}
              >
                <div className="input text-start flex items-center pl-3">
                  <Icon src={selectedNetwork} size="base" />

                  {selectedNetwork?.name}
                  {!selectedNetwork && (
                    <p className="text-description">Select a network</p>
                  )}

                  <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                    <FontAwesomeIcon icon={faCaretDown} size="sm" />
                  </div>
                </div>
              </DropdownMenu>
            </div>
          </div>
        )}
        {receiveCurrency?.currencyType === 'CryptoCurrency' && (
          <React.Fragment>
            <p className="text-description-subtle mb-2">Wallet</p>
            <DropdownMenu
              renderList={
                walletsData && walletsData?.length > 0 ? (
                  [...walletsData]
                    .sort((a, b) => {
                      const compatibleA =
                        isWalletAndCryptoCurrencyPairCompatible(
                          a,
                          receiveCurrency
                        )
                      const compatibleB =
                        isWalletAndCryptoCurrencyPairCompatible(
                          b,
                          receiveCurrency
                        )
                      if (compatibleA && !compatibleB) return -1
                      return 0
                    })
                    .map((item, idx) => {
                      return (
                        <DropdownCell
                          key={item.id}
                          title={item.address}
                          subtitle={item.network?.name}
                          onClick={() => setWallet(item)}
                          isActive={wallet === item}
                          imageName={item.network}
                          disabled={
                            !isWalletAndCryptoCurrencyPairCompatible(
                              item,
                              receiveCurrency
                            )
                          }
                        />
                      )
                    })
                ) : (
                  <DropdownCell
                    title={'Add new wallet'}
                    subtitle={`Your account don't have any wallets, press to add a new.`}
                    onClick={() => history.push('/app/wallets')}
                    isActive={false}
                    icon={
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="text-brand-accent dark:text-white text-lg"
                      />
                    }
                  />
                )
              }
            >
              <div className="input text-start flex items-center pl-3">
                {wallet && <Icon src={wallet.network} size="base" />}

                {wallet?.address}
                {!wallet && <p className="text-description">Select a wallet</p>}

                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              </div>
            </DropdownMenu>
          </React.Fragment>
        )}
        {receiveCurrency?.currencyType === 'FiatCurrency' && (
          <React.Fragment>
            <p className="text-description-subtle mb-2">Bank account</p>
            <DropdownMenu
              renderList={
                bankAccountsData && bankAccountsData?.length > 0 ? (
                  bankAccountsData?.map((item, idx) => {
                    return (
                      <DropdownCell
                        key={item.id}
                        title={item.name}
                        subtitle={item.description || ''}
                        onClick={() => setBankAccount(item)}
                        isActive={bankAccount === item}
                      />
                    )
                  })
                ) : (
                  <DropdownCell
                    title={'Add new bank account'}
                    subtitle={`Your account don't have any bank accounts, press to add a new.`}
                    onClick={() => history.push('/app/bank-accounts')}
                    isActive={false}
                    icon={
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="text-brand-accent dark:text-white text-lg"
                      />
                    }
                  />
                )
              }
            >
              <div className="input text-start flex items-center pl-3">
                {bankAccount?.name}
                {!bankAccount && (
                  <p className="text-description">Select a bank account</p>
                )}

                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              </div>
            </DropdownMenu>
          </React.Fragment>
        )}
      </div>

      <button
        disabled={
          (receiveCurrency?.currencyType === 'CryptoCurrency' && !wallet) ||
          (receiveCurrency?.currencyType === 'FiatCurrency' && !bankAccount) ||
          isAboutToFetchQuotation ||
          Boolean(invalidCurrencyPair) ||
          payAmount === 0 ||
          receiveAmount === 0
        }
        className="button mt-8"
        onClick={swapSummary.onOpen}
      >
        {isAboutToFetchQuotation ? <Spinner size="sm" /> : 'Continue'}
      </button>
      {invalidCurrencyPair && (
        <p className="text-warning mt-[10px]">{invalidCurrencyPair}</p>
      )}
    </div>
  )
}

export default CreateSwap
